import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project1.png";
import projImg2 from "../assets/img/project2.png";
import projImg3 from "../assets/img/project3.png";
import projImg4 from "../assets/img/project4.png";
import projImg5 from "../assets/img/project5.png";
import webProjImg1 from "../assets/img/web-project1.jpg";
import webProjImg2 from "../assets/img/web-project2.jpg";
import webProjImg3 from "../assets/img/web-project3.jpg";
import webProjImg4 from "../assets/img/web-project4.jpg";
import webProjImg5 from "../assets/img/web-project5.jpg";
import graphicImg1 from "../assets/img/graphicImg1.png";
import graphicImg2 from "../assets/img/graphicImg2.png";
import graphicImg3 from "../assets/img/graphicImg3.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';

export const Projects = () => {

  const projects = [
    {
      title: "Boat Landing Page",
      description: "UI Design",
      imgUrl: projImg1,
      link: "https://www.behance.net/gallery/137253943/boAt-Landing-Page-Redesign"
    },
    {
      title: "Apple Facetime",
      description: "UX Design",
      imgUrl: projImg4,
      link: "https://www.behance.net/gallery/148769933/Apple-Facetime-Group-Call-UX-Analysis"
    },
    {
      title: "Resto Airport App",
      description: "UX Design",
      imgUrl: projImg5,
      link: "https://www.behance.net/gallery/147094091/Resto-Airport-Food-Ordering-Concept"
    },
    {
      title: "User Profile",
      description: "UI Design",
      imgUrl: projImg2,
      link: "https://www.behance.net/gallery/138599463/User-Profile-UI-Design"
    },
    {
      title: "Travel Agency Web Design",
      description: "UI Design",
      imgUrl: projImg3,
      link: "https://www.behance.net/gallery/160089159/Travel-Agency-Website-UI"
    },
  ];

  const webprojects = [
    {
      title: "MovieLand",
      description: "React App",
      imgUrl: webProjImg1,
      link: "https://mov1eland.netlify.app/"
    },
    {
      title: "Tic-Tac-Toe",
      description: "React App",
      imgUrl: webProjImg2,
      link: "https://play-tictac-toe.netlify.app/"
    },
    {
      title: "Career Service App",
      description: "Angular App",
      imgUrl: webProjImg3,
      link: "https://career-service-app.web.app/"
    },
    {
      title: "Marine Conservation Blog",
      description: "Wix Website",
      imgUrl: webProjImg4,
      link: "https://nanak1313.wixsite.com/marine-conservation"
    },
    {
      title: "GSA Pipes",
      description: "Wix Website",
      imgUrl: webProjImg5,
      link: "https://nanak1313.wixsite.com/gsapipes"
    },
  ];

  const graphics = [
    {
      title: "Marketing Materials",
      description: "Graphic Banner Designs",
      imgUrl: graphicImg1,
      link: "https://dribbble.com/shots/19550176-Web-Banner-Design"
    },
    {
      title: "Marketing Materials",
      description: "Graphic Banner Designs",
      imgUrl: graphicImg2,
      link: "https://dribbble.com/shots/17408719-Banner-Shopping-App"
    },
    {
      title: "Ride Home Banner",
      description: "Graphic Banner Designs",
      imgUrl: graphicImg3,
      link: "https://dribbble.com/shots/24241587-Marketing-Designs-for-RideHome"
    },
  ];
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
              <div>
                <h2>Projects</h2>
                <Tab.Container id="projects-tabs" defaultActiveKey="second">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="second">Web Development</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="first">UI / UX Design</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Graphic Design</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          webprojects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          graphics.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="bg-img"></img>
    </section>
  )
}
