import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo2.svg";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/Be.svg';
import navIcon3 from '../assets/img/dribbble.svg';
import navIcon4 from '../assets/img/github.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center" align="center">
          {/* <Newsletter /> */}
          <Col size={12} sm={3} className="text-sm-center">
            <img className="logo" src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-sm-center">
          <div className="social-icon">
                <a href="https://www.linkedin.com/in/angadnanda/" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="Linkedin" /></a>
                <a href="https://www.behance.net/angadnanda" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="Behance" /></a>
                <a href="https://dribbble.com/angadnanda" target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="Dribbble" /></a>
                <a href="https://github.com/PreetAngadSingh" target="_blank" rel="noopener noreferrer"><img src={navIcon4} alt="Instagram" /></a>
              </div>
          </Col>
          <Col size={12} sm={3} className="text-center text-sm-end">
            <p className="mb-0">Copyright 2024. All Rights Reserved</p>
            <p className="mt-0">Made with ❤️ in React</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
